import ep1st1 from './Ep 1 Still 1.jpg';
import ep1st2 from './Ep 1 Still 2.jpg';
import ep1st3 from './Ep 1 Still 3.jpg';
import ep2st1 from './Ep 2 Still 1.jpg';
import ep2st2 from './Ep 2 Still 2.jpg';
import ep2st3 from './Ep 2 Still 3.jpg';
import ep3st1 from './Ep 3 Still 1.jpg';
import ep3st2 from './Ep 3 Still 2.jpg';
import ep3st3 from './Ep 3 Still 3.jpg';

export const images = [
  ep1st1,
  ep1st2,
  ep1st3,
  ep2st1,
  ep2st2,
  ep2st3,
  ep3st1,
  ep3st2,
  ep3st3
]